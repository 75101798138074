import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { InputFormik, InputCheckboxFormik } from '@components/core/Input';
import { SelectFormik } from '@components/core/Select';
import { useStaticQuery, graphql } from 'gatsby';
import * as Yup from 'yup';
import Text from '@components/core/Text';
import useCaptchaValidation from '@hooks/useCaptchaValidation';
import Button from '@components/core/Button';
import FormHelperText from '@components/core/FormHelperText/FormHelperText';
import PropTypes from 'prop-types';
import styles from './hire-form.module.scss';

const FORM_ID = '9da2e81b-ff82-47ca-85d9-9bda483546ff';

const initialValues = {
    name: '',
    lastName: '',
    email: '',
    job: '',
    industry: '',
    message: '',
    boxes: '',
    otherJob: '',
    otherIndustry: '',
};

const validateOptions = Yup.object({
    name: Yup.string().required('Please fill this space'),
    lastName: Yup.string().required('Please fill this space'),
    company: Yup.string().required('Please fill this space'),
    email: Yup.string()
        .email('Invalid email adress')
        .required('An email is required'),
    job: Yup.string().required('Please select a job title'),
    industry: Yup.string().required('Please select an industry'),
    boxes: Yup.string().required('Please select one option'),
});

const HireForm = ({ policy }) => {
    const dropDownValues = graphql`
        query GetSelectOptions {
            data: allMarkdownRemark(
                filter: {
                    fields: { slug: { glob: "/util-list/*" } }
                    frontmatter: { listTitle: { eq: "JobLists" } }
                }
            ) {
                utilsList: nodes {
                    frontmatter {
                        jobsTitle {
                            title
                        }
                        industry {
                            title
                        }
                    }
                }
            }
        }
    `;

    const {
        data: {
            utilsList: [utilsData],
        },
    } = useStaticQuery(dropDownValues);

    const [formFeedback, setFormFeedback] = useState();

    const listofJobs = utilsData.frontmatter.jobsTitle;
    const listOfIndustries = utilsData.frontmatter.industry;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validateOptions}
            onSubmit={async (values, { setStatus }) => {
                setFormFeedback({
                    type: 'success',
                    message: 'Please wait, we’re submitting your info.',
                });
                const onSubmit = ({ token }) => {
                    const fields = [
                        {
                            name: 'firstname',
                            value: values.name,
                        },
                        {
                            name: 'lastname',
                            value: values.lastName,
                        },
                        {
                            name: 'company',
                            value: values.company,
                        },
                        {
                            name: 'email',
                            value: values.email,
                        },
                        {
                            name: 'job_role',
                            value: values.job,
                        },
                        {
                            name: 'industry_dropdown_',
                            value: values.industry,
                        },
                        {
                            name: 'message',
                            value: values.message,
                        },
                        {
                            name: 'referral_yes_no',
                            value: values.boxes,
                        },
                    ];

                    if (values.job === 'Other') {
                        const jobTitle = {
                            name: 'jobtitle',
                            value: values.otherJob,
                        };
                        fields.push(jobTitle);
                    }

                    const formData = {
                        fields,
                        formId: FORM_ID,
                        gToken: token,
                    };

                    fetch(
                        `${process.env.GATSBY_LAMBDA_HUBSPOT_CONNECTOR_API}/forms`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(formData),
                        }
                    )
                        .then(response => response.json())
                        .then(data => {
                            const { status } = data;
                            if (status === 'error') {
                                setFormFeedback({
                                    type: 'error',
                                    message: 'Please try again later',
                                });
                            } else {
                                setStatus('sent');
                            }
                        });
                };

                const token = await useCaptchaValidation();
                onSubmit({ token });
            }}
        >
            {({ values, isSubmitting, handleSubmit, status }) => {
                return (
                    <>
                        {status === 'sent' ? (
                            <>
                                <Text
                                    as="h3"
                                    size="h3"
                                    className={styles.thanksTitle}
                                >
                                    Thank you for contacting us!
                                </Text>

                                <Text as="span" size="paragraphMd">
                                    We’re pretty responsive, like our design.
                                    <br /> So be on the lookout for a ping back
                                    from us.
                                </Text>
                            </>
                        ) : (
                            <Form onSubmit={handleSubmit}>
                                <Text color="gray" as="p" size="paragraphSm">
                                    Required *
                                </Text>
                                <InputFormik
                                    name="name"
                                    type="text"
                                    placeholder="First Name *"
                                />
                                <InputFormik
                                    name="lastName"
                                    type="text"
                                    placeholder="Last Name *"
                                />
                                <InputFormik
                                    name="company"
                                    type="text"
                                    placeholder="Company *"
                                />
                                <InputFormik
                                    name="email"
                                    type="text"
                                    placeholder="Email *"
                                />
                                <SelectFormik name="job">
                                    <option value="">Job title *</option>
                                    {listofJobs.map(job => (
                                        <option
                                            key={job.title}
                                            value={job.title}
                                        >
                                            {job.title}
                                        </option>
                                    ))}
                                </SelectFormik>
                                {values.job === 'Other' && (
                                    <InputFormik
                                        name="otherJob"
                                        type="text"
                                        placeholder="My job is..."
                                    />
                                )}
                                <SelectFormik name="industry">
                                    <option value="">Industry *</option>
                                    {listOfIndustries.map(industry => (
                                        <option
                                            key={industry.title}
                                            value={industry.title}
                                        >
                                            {industry.title}
                                        </option>
                                    ))}
                                </SelectFormik>
                                {values.industry === 'Other' && (
                                    <InputFormik
                                        name="otherIndustry"
                                        type="text"
                                        placeholder="My industry is..."
                                    />
                                )}
                                <InputFormik
                                    name="message"
                                    type="text"
                                    placeholder="Message"
                                />
                                <Text as="p" size="paragraphSm">
                                    Were you referred to us? *
                                </Text>
                                <div className={styles.boxesContainer}>
                                    <InputCheckboxFormik
                                        value={values.boxes}
                                        id="box"
                                        name="boxes"
                                    />
                                </div>
                                <Text
                                    className={styles.policy}
                                    as="p"
                                    size="paragraphSm"
                                >
                                    By clicking on “Send” I accept the{' '}
                                    <a
                                        href={
                                            policy.privacyPolicyFile.publicURL
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        className={styles.underline}
                                    >
                                        Privacy Policy
                                    </a>
                                </Text>
                                <Button
                                    disabled={isSubmitting}
                                    variant="dark"
                                    type="submit"
                                >
                                    <Text as="span" size="paragraphSm">
                                        Send
                                    </Text>
                                    <img
                                        height="1"
                                        width="1"
                                        style={{ display: 'none' }}
                                        alt=""
                                        src="https://px.ads.linkedin.com/collect/?pid=2733474&conversionId=3871226&fmt=gif"
                                    />
                                </Button>
                                {formFeedback && (
                                    <FormHelperText
                                        type={formFeedback.type}
                                        message={formFeedback.message}
                                        className="formFeedback"
                                    />
                                )}
                            </Form>
                        )}
                    </>
                );
            }}
        </Formik>
    );
};

HireForm.propTypes = {
    policy: PropTypes.shape({
        privacyPolicyFile: PropTypes.shape({
            publicURL: PropTypes.string,
        }),
        title: PropTypes.string,
    }).isRequired,
};

export default HireForm;
