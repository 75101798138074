import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import Eyebrow from '@components/core/Eyebrow';
import Text from '@components/core/Text';
import styles from './location-card.module.scss';

const LocationCard = ({ eyebrow, title, image, phone }) => {
    return (
        <div>
            <Image className={styles.image} fluid={image} />
            <Eyebrow
                size="paragraphSm"
                className={styles.eyebrow}
                casing="uppercase"
            >
                {eyebrow}
            </Eyebrow>
            <Text size="h4" className={styles.title}>
                {title}
            </Text>
            <Text size="h4" className={styles.title}>
                {phone}
            </Text>
        </div>
    );
};

LocationCard.propTypes = {
    eyebrow: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    image: PropTypes.shape({
        aspectRatio: PropTypes.number.isRequired,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string.isRequired,
        sizes: PropTypes.string.isRequired,
    }).isRequired,
};

export default LocationCard;
