import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid, { Column, Row } from '@components/core/Grid';
import SectionHeader from '@components/SectionHeader';
import Section from '@components/core/Section';
import HireForm from '@components/FormsContactUs/HireForm';
import InterviewForm from '@components/FormsContactUs/InterviewForm';
import Select from '@components/core/Select';
import GreetForm from '@components/FormsContactUs/GreetForm';
import HiredMessage from '@components/FormsContactUs/HiredMessage';
import Text from '@components/core/Text';
import SocialMedia from '@components/SocialMedia';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './contact-us-form.module.scss';

const getCurrentForm = {
    hireform: HireForm,
    interviewForm: InterviewForm,
    greetForm: GreetForm,
    hiredMessage: HiredMessage,
};

const listForms = [
    { value: 'hireform', text: 'I want to hire Zemoga' },
    { value: 'interviewForm', text: 'I want to interview Zemoga' },
    { value: 'greetForm', text: 'I just want to say hi' },
    { value: 'hiredMessage', text: 'I want to be hired' },
];

const ContactUsForm = ({ data }) => {
    const [selectedForm, setSelectedForm] = useState('hireform');
    const CurrenFormComponent = getCurrentForm[selectedForm];
    const { md } = useBreakpoint();
    const {
        markdownRemark: {
            frontmatter: { privacyPolicy },
        },
    } = useStaticQuery(graphql`
        query {
            markdownRemark(
                frontmatter: { listTitle: { eq: "PrivacyPolicy" } }
            ) {
                frontmatter {
                    privacyPolicy {
                        privacyPolicyFile {
                            publicURL
                        }
                        title
                    }
                }
            }
        }
    `);

    const privacyPolicyToUse = privacyPolicy.find(
        policy => policy.title === 'Privacy Policy'
    );

    return (
        <Section>
            <Grid>
                <Row className={styles.containerForm}>
                    <Column md="4" className={styles.containerText}>
                        <SectionHeader
                            className={styles.header}
                            eyebrow={data.sectionHeader.eyebrow}
                            title={data.sectionHeader.title}
                            summary={data.sectionHeader.description}
                        />
                        {md && <SocialMedia />}
                    </Column>
                    <Column md="2" />
                    <Column md="6">
                        <div className={styles.container}>
                            <Text
                                className={styles.contacting}
                                as="p"
                                size="paragraphSm"
                            >
                                I’m contacting you because
                            </Text>
                            <Select
                                value={selectedForm}
                                onChange={event => {
                                    setSelectedForm(event.target.value);
                                }}
                            >
                                {listForms.map(opt => (
                                    <option key={opt.text} value={opt.value}>
                                        {opt.text}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <CurrenFormComponent policy={privacyPolicyToUse} />
                    </Column>
                </Row>
                <Row className={styles.containerMedia}>
                    <Column col="2">
                        <div className={styles.media}>
                            <SocialMedia />
                        </div>
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

ContactUsForm.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContactUsForm;
