import React from 'react';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Section from '@components/core/Section';
import PageHeader from '@components/PageHeader';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import OurPresence from '@sections/ContactUs/OurPresence';
import ContactUsForm from '@sections/ContactUs/ContactUsForm';
import styles from './contact-us.module.scss';

const ContactUsPage = ({
    data: {
        markdownRemark: {
            frontmatter: { title, seo, pageHeader, ourPresence, contactUs },
        },
    },
}) => {
    const seoData = {
        title: (seo && seo.title) || title,
        description: (seo && seo.description) || '',
        image: (seo && seo.image && seo.image.publicURL) || '',
    };

    return (
        <Layout footerCompact navBarExternalBg={false}>
            <SEO
                title={seoData.title}
                description={seoData.description}
                image={seoData.image}
            />
            <Section className={styles.careersHeaderSection}>
                <PageHeader infoHeader={pageHeader} />
            </Section>
            <ContactUsForm data={contactUs} />
            <OurPresence data={ourPresence} />
        </Layout>
    );
};

ContactUsPage.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
    query ContactUsPage {
        markdownRemark(fields: { slug: { eq: "/pages/contact-us/" } }) {
            frontmatter {
                seo {
                    title
                    description
                    image {
                        publicURL
                    }
                }
                pageHeader {
                    title
                    headerDescription: description
                    image {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    previewUrl {
                        src: publicURL
                    }
                    videoUrl
                    videoCms {
                        src: publicURL
                    }
                }
                contactUs {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                }
                ourPresence {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                }
            }
        }
    }
`;

export default ContactUsPage;
