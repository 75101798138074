import React from 'react';
import Facebook from '@components/Icons/facebook.svg';
import Instagram from '@components/Icons/instagram.svg';
import Twitter from '@components/Icons/twitter.svg';
import Linkedin from '@components/Icons/linkedin.svg';

const socialMediaData = [
    {
        url: 'https://www.facebook.com/Zemoga/',
        icon: <Facebook />,
    },
    {
        url: 'https://www.instagram.com/zemoga_inc/',
        icon: <Instagram />,
    },
    {
        url: 'https://twitter.com/Zemoga',
        icon: <Twitter />,
    },
    {
        url: 'https://www.linkedin.com/company/zemoga-inc/',
        icon: <Linkedin />,
    },
];

export default socialMediaData;
