import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { InputFormik } from '@components/core/Input';
import { SelectFormik } from '@components/core/Select';
import * as Yup from 'yup';
import useCaptchaValidation from '@hooks/useCaptchaValidation';
import Button from '@components/core/Button';
import FormHelperText from '@components/core/FormHelperText/FormHelperText';
import Text from '@components/core/Text';
import PropTypes from 'prop-types';
import countriesData from '../../../utils/countries.json';
import styles from './interview-form.module.scss';

const FORM_ID = '0aaee607-ffe0-4da8-9a19-cb179d259266';

const InterviewForm = ({ policy }) => {
    const { countries } = countriesData;
    const [formFeedback, setFormFeedback] = useState();

    return (
        <Formik
            initialValues={{
                name: '',
                lastName: '',
                email: '',
                phone: '',
                outlet: '',
                country: '',
                inquiry: '',
            }}
            validationSchema={Yup.object({
                name: Yup.string().required('Please fill this space'),
                lastName: Yup.string().required('Please fill this space'),
                email: Yup.string()
                    .email('Invalid email adress')
                    .required('An email is required'),
                outlet: Yup.string().required('Please fill this space'),
                country: Yup.string().required('Please select a country'),
                inquiry: Yup.string().required('Please fill this space'),
            })}
            onSubmit={async (values, { setStatus }) => {
                setFormFeedback({
                    type: 'success',
                    message: 'Please wait, we’re submitting your info.',
                });
                const onSubmit = ({ token }) => {
                    const fields = [
                        {
                            name: 'firstname',
                            value: values.name,
                        },
                        {
                            name: 'lastname',
                            value: values.lastName,
                        },
                        {
                            name: 'email',
                            value: values.email,
                        },
                        {
                            name: 'phone',
                            value: values.phone,
                        },
                        {
                            name: 'company',
                            value: values.outlet,
                        },
                        {
                            name: 'country_dropdown',
                            value: values.country,
                        },
                        {
                            name: 'message',
                            value: values.inquiry,
                        },
                    ];
                    const formData = {
                        fields,
                        formId: FORM_ID,
                        gToken: token,
                    };

                    fetch(
                        `${process.env.GATSBY_LAMBDA_HUBSPOT_CONNECTOR_API}/forms`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(formData),
                        }
                    )
                        .then(response => response.json())
                        .then(data => {
                            const { status } = data;
                            if (status === 'error') {
                                setFormFeedback({
                                    type: 'error',
                                    message: 'Please try again later',
                                });
                            } else {
                                setStatus('sent');
                            }
                        });
                };

                const token = await useCaptchaValidation();
                onSubmit({ token });
            }}
        >
            {({ isSubmitting, handleSubmit, status }) => {
                return (
                    <>
                        {status === 'sent' ? (
                            <>
                                <Text
                                    as="h3"
                                    size="h3"
                                    className={styles.thanksTitle}
                                >
                                    Thank you for contacting us!
                                </Text>
                                <Text as="span" size="paragraphMd">
                                    We’re pretty responsive, like our design.
                                    <br /> So be on the lookout for a ping back
                                    from us.
                                </Text>
                            </>
                        ) : (
                            <Form onSubmit={handleSubmit}>
                                <Text color="gray" as="p" size="paragraphSm">
                                    Required *
                                </Text>
                                <InputFormik
                                    name="name"
                                    type="text"
                                    placeholder="First Name *"
                                />
                                <InputFormik
                                    name="lastName"
                                    type="text"
                                    placeholder="Last Name *"
                                />
                                <InputFormik
                                    name="email"
                                    type="text"
                                    placeholder="Email *"
                                />
                                <InputFormik
                                    name="phone"
                                    type="text"
                                    placeholder="Phone Number"
                                />
                                <InputFormik
                                    name="outlet"
                                    type="text"
                                    placeholder="Media Outlet *"
                                />
                                <SelectFormik name="country">
                                    <option value="">Country *</option>
                                    {countries.map(country => (
                                        <option
                                            key={country.id}
                                            value={country.name}
                                        >
                                            {country.name}
                                        </option>
                                    ))}
                                </SelectFormik>
                                <InputFormik
                                    name="inquiry"
                                    type="text"
                                    placeholder="Press Inquiry *"
                                />
                                <Text
                                    className={styles.policy}
                                    as="p"
                                    size="paragraphSm"
                                >
                                    By clicking on “Send” I accept the{' '}
                                    <a
                                        href={
                                            policy.privacyPolicyFile.publicURL
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        className={styles.underline}
                                    >
                                        Privacy Policy
                                    </a>
                                </Text>
                                <Button
                                    disabled={isSubmitting}
                                    variant="dark"
                                    type="submit"
                                >
                                    <Text as="span" size="paragraphSm">
                                        Send
                                    </Text>
                                    <img
                                        height="1"
                                        width="1"
                                        style={{ display: 'none' }}
                                        alt=""
                                        src="https://px.ads.linkedin.com/collect/?pid=2733474&conversionId=3871346&fmt=gif"
                                    />
                                </Button>
                                {formFeedback && (
                                    <FormHelperText
                                        type={formFeedback.type}
                                        message={formFeedback.message}
                                        className="formFeedback"
                                    />
                                )}
                            </Form>
                        )}
                    </>
                );
            }}
        </Formik>
    );
};

InterviewForm.propTypes = {
    policy: PropTypes.shape({
        privacyPolicyFile: PropTypes.shape({
            publicURL: PropTypes.string,
        }),
        title: PropTypes.string,
    }).isRequired,
};

export default InterviewForm;
