import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import LocationCard from '@components/Cards/Location';
import SectionHeader from '@components/SectionHeader';
import Grid, { Column, Row } from '@components/core/Grid';
import Section from '@components/core/Section';
import { formatArrayData } from '../../../utils/utils';
import styles from './our-presence.module.scss';

const query = graphql`
    query GetAllOffices {
        result: allMarkdownRemark(
            filter: { fields: { slug: { glob: "/locations/*" } } }
        ) {
            nodes {
                frontmatter {
                    city
                    address
                    phone
                    complement
                    office
                    image {
                        childImageSharp {
                            fluid(maxWidth: 500) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;

const OurPresence = ({ data }) => {
    const {
        result: { nodes },
    } = useStaticQuery(query);

    const locations = formatArrayData(nodes);

    return (
        <Section className={styles.section}>
            <Grid>
                <Row>
                    <Column md="7">
                        <SectionHeader
                            eyebrow={data.sectionHeader.eyebrow}
                            title={data.sectionHeader.title}
                            summary={data.sectionHeader.description}
                        />
                    </Column>
                </Row>

                <div className={styles.locationsList}>
                    {locations.map(
                        ({
                            city,
                            address,
                            phone,
                            complement,
                            office,
                            image,
                        }) => (
                            <LocationCard
                                key={city}
                                eyebrow={city}
                                title={`${address}, ${office ||
                                    ''} ${complement}`}
                                phone={`Phone ${phone}`}
                                image={image.childImageSharp.fluid}
                            />
                        )
                    )}
                </div>
            </Grid>
        </Section>
    );
};

OurPresence.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
    }).isRequired,
};

export default OurPresence;
