import React from 'react';
import Text from '@components/core/Text';
import socialMediaData from '../../utils/socialMedia';
import styles from './social-media.module.scss';

const SocialMedia = () => (
    <div className={styles.container}>
        <Text as="p" size="paragraphSm">
            Follow Us
        </Text>
        <div className={styles.socialMedia}>
            {socialMediaData.map(media => (
                <a
                    key={media.url}
                    href={media.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    {media.icon}
                </a>
            ))}
        </div>
    </div>
);

export default SocialMedia;
