import React from 'react';
import { Link } from 'gatsby';
import Text from '@components/core/Text';

const HiredMessage = () => {
    return (
        <Text as="p" size="paragraphMd">
            If you want to work with us, look at our exciting opportunities on
            our{' '}
            <Link to="/careers">
                <Text color="blue" as="span" size="paragraphMd">
                    careers page
                </Text>
            </Link>
        </Text>
    );
};

export default HiredMessage;
